<template>
    <div>
      <i class="hover-icon2 fa-solid fa-grip-dots fa-rotate-90" style="color: white; font-size: 20px;" data-v-168f92de="" aria-hidden="true"></i>
      <div class="content-list" level="1" :id="this.childNodes.id" :class="{isImported : this.isImported}">
        <div class="content-list-category"><p>{{this.isImported ? capitalizeFirstLetter(this.childNodes.value) : `${this.childNodes.name} - ${this.childNodes.value}`}}</p></div>
        <div class="content-list-wrap">
          <nested :index="1" v-model="elements" :isImported="this.isImported" :siblings="siblingIDs"></nested>
        </div>
      </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex"
import nested from './nested';
export default {
    props: ['childNodes', 'isImported'],
    data() {
    return {
      elements: [],
      siblingIDs: [],
      noDragArr: []
    };
  },
    components: {
        nested
    },
    computed:{
        ...mapGetters("views", ["getSidebarOpenState"]),
		...mapGetters("client", ["getLockContentTree","getClientContentTree", "getChangedNode", "getContentTreeFilters", "getCurrentNode", "getClientData", "getHighlightedNode", "getListView", "getClientSlug", "getContentTreeSiblingIds", "getNodesSiblingData"]),
    },
    async mounted(){
        try{
            this.elements = this.childNodes.elements;
        }catch(error){
            console.log('mounted catch error', error);
        }
    },
    methods:{
      capitalizeFirstLetter(string) {
          return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }
}
</script>

<style>
.content-list > .content-list-wrap{
  width: 100%;
  height: 450px !important;
  overflow: scroll;
}
</style>