<template>
    <div class="content-master">
      <ContentListMaster v-if="getListView"/>
      <ContentTree v-if="getListView === false && reload === true && hasData === true" :key="componentKey"/>
    </div>
  </template>
  <script>
import {mapGetters} from "vuex"
import ContentListMaster from './ContentListMaster.vue';
import ContentTree from './ContentTree.vue';
import { useRoute } from 'vue-router';

export default {
    components: {
        ContentListMaster,
        ContentTree,
    },
    data(){
      return{
        reload: false,
        hasData: false,
        componentKey: 0  // add this line,
      }
    },
    watch:{
      getReloadContentTree(){
        this.reload = false;
        this.componentKey += 1;  // increment the key to force re-render
        setTimeout(() => {
            this.reload = true;
            this.$store.commit('client/setContentTreeWasResized', true);
        }, 500);
      }
    },
   async mounted(){

    this.$store.commit('client/setHidePageLoader', false);

    const route = useRoute();
    const clientParam = route.params.client;

    const url = new URL(window.location.href);

    if(url.searchParams.get('list') === 'true'){
      console.log('closing the sidebar contentmaster mounted');
      this.$store.commit('views/closeSidebar');
      this.$store.commit('client/setListView', true)
    }else{
      this.$store.commit('client/setListView', false);
    }

    console.log('content master mounted');

      try{
        this.reload = true;

        let resizeTimeout;
        window.addEventListener("resize", () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                this.reload = false;
                setTimeout(() => {
                    this.reload = true;
                    this.$store.commit('client/setContentTreeWasResized', true);
                }, 500);
            }, 500);
        });

        const fetchContentTreePromise = this.$store.dispatch('client/fetchClientContentTree', clientParam);

        fetchContentTreePromise.then(() => {
              this.hasData = true;
        });

        await Promise.all([
            fetchContentTreePromise,
            this.$store.dispatch('client/fetchClientData', clientParam),
            this.$store.dispatch('auth/updateAuthUserAction', 'OPEN CONTENT')
        ]);
        
      }catch(error){
        console.log('contentmaster catch error', error);
      }
    },
    computed:{
            ...mapGetters("client", ["getListView", 'getClientSlug', 'getReloadContentTree']),
    },
}
</script>